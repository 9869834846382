@use 'components/shared/core.scss' as *;

.receipt-modal .modal-container {
   height: 100%;
   background: transparent;
   .modal-content {
      height: 100%;
      justify-content: center;
   }
}

.receipt-image {
   max-height: 98%;
   color: $white;
}

.receipt-close {
   width: 150px;
}