@use 'components/shared/core.scss' as *;
.reset-password {
    height: calc(100vh - $header-height);
}
.reset-password-form-container {
    color: $white;
    position: absolute;
    top: 50%;
    left : 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 600px;
    background-color: $grey-darkest;
    border-radius: $radius;
    padding-bottom: $size-xxs;
    box-shadow: 0px 0px 150px #fff8;
    text-align: center;
    .input-label {
        color: $white;
        margin-left: 0;
        padding-left: 0;
    }
    .reset-password-input {
        color: $black;
    }
    .checkbox-label {
        color: white;
    }
}

.reset-password-logo {
    margin-top: $size-3xs;
    margin-bottom: $size-3xs;
    text-align: center;
    .reset-password-logo-image {
        height: 100px;
    }
}

.reset-password-error {
    height:$size-xxs;
    min-height: $size-xxs;
    font-size: $size-font;
    line-height: $size-xxs;
    margin-bottom: $size-5xs;
    text-align: center;
    color: $red;
}

.reset-cancel-link-container {
    margin-top: $size-3xs;
}

.reset-padding {
    margin-bottom: $size-3xs;
}
