@use 'components/shared/core.scss' as *;

.icon {
    position: relative;
    width: $size-xs;
    height: $size-xs;
    display: block;
    &.spacer{
        margin-right: $size-6xs;
    }
    &.clickable {
        cursor: pointer;
    }
    i {
        height: $size-xs;
        width: $size-xs;
    }
    svg {
        position: absolute;
        top: 0px;
        left: 0px;
        height: $size-xs;
        width: $size-xs;
    }
}