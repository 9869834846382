
@use 'components/shared/core.scss' as *;

.tooltip-anchor{
    position: relative;
}

.tooltip {
    position: absolute;
    width: auto;
    max-width: 300px;
    background-color: transparent;
    color: transparent;
    border-radius: $radius-small;
    font-size: $size-font;
    text-align: left;
    z-index: -1;
    &::selection {
        background-color: $secondary-color;
    }
    &.show {
        border: none;
        color: $white;
        z-index: 5;
        background-color: $primary-color;
        border: 1px solid $secondary-color-light;
    }
}

.tooltip-content {
    margin: $size-4xs;
    overflow-y: scroll;
    word-wrap: break-word;
    max-height: 600px;
}

.tooltip-ellipses {
    position: absolute;
    color: $white;
    height: $size-font;
    line-height: $size-font;
    font-size: $size-font;  
    bottom: 5px;
    width: 100%;
    text-align: center;  
}
