@use 'components/shared/core.scss' as *;


.user-locations-modal-arrow-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.user-locations-modal-arrow {
    color: $secondary-color;
}

.user-locations-modal-label {
    min-height: $size-small;
    font-weight: bold;
    font-size: $size-xxs;
    margin-bottom: $size-5xs;
    &.left {text-align: right};
}

.user-locations-modal-item {
    min-height: $size-small;
    font-size: $size-font;
    &.filler {
        display: flex;
        flex-direction: column;
        height: 100%;
        color: $grey-light;
        justify-content: center;
    }
    &.left {text-align: right};

}