@use 'components/shared/core.scss' as *;
@use 'sass:color';

.set-location-modal.modal-backdrop{
    justify-content: flex-start;
    .modal-container {
        margin-top: 10%;
        width: 80%;
        max-width: 450px;
        padding: $size-xxs;
        border-radius: $radius;
        background: $white;
    }
    .modal-title {
        width: 80%;
        font-size: $size-xxs;
        margin-top: $size-3xs;
        font-weight: bold;
        color: $primary-color;
    }
    .location-list-container{
        width: 100%;
        margin-bottom: $size-4xs;
        max-height: 200px;
        overflow-y: scroll;
        background-color: color.scale($grey-lightest, $lightness: 8%);
        .list-row {
            width: calc(100% - $size-small);
            height: $size-xxs;
            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            margin-left: $size-small;
            margin-bottom: $size-5xs;
        }
        .list-state-icon {
            display: block;
            position: relative;
            width: $size-font;
            height: $size-font;
            margin-right: $size-5xs;
            border: 1px solid $primary-color;
            background-color: $primary-color-lighter;
            border-radius: $size-6xs;
            transition: all .3s;
            .checkmark {
                position: absolute;
                height: $size-xs;
                width: $size-xs;
                border-radius: $radius-small;
                transition: all .3s;
                &:after{
                    position: absolute;
                    border: solid $primary-color-lighter;
                }
            }
            &.success {
                background-color: $primary-color-lightest;
                border-color: $green;
                border-radius: 50%;
                .checkmark:after {
                    top: 1px;
                    left: 4px;
                    content: '';
                    width: 3px;
                    height: 7px;
                    border: solid $green;
                    border-width: 0 2px 2px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
            }
            &.fail {
                background-color: $primary-color-lightest;
                border-color: $red;
                .checkmark:after {
                    position: absolute;
                    top: 6px;
                    left: 7px;
                    font-size: $size-3xs;
                    font-weight: bold;
                    border: none;
                    content: 'X'; /* Add content property with 'x' value */
                    color: $red;
                    transform: translate(-50%, -50%); /* Optional: Center the 'x' */
                }
            }
        }
        .list-label {
            height: $size-xxs;
            line-height: $size-xxs;
            font-size: $size-3xs;
            color: $primary-color;
        }
    }
}