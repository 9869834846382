@use 'components/shared/core.scss' as *;

.table {
    width: 100%;
    font-size: $size-font;
    border: 1px solid $grey-lightest;
    border-radius: $radius;
    &.freeze {
        pointer-events: none;
    }
}

.table-title {
    font-size: $size-xs;
    color: $primary-color-darker;;
    line-height: $size-xs;
    margin-top: $size-3xs;
    margin-left: $size-3xs;
    margin-bottom: $size-3xs;
    box-sizing: border-box;
    &.margin-top {
        margin-top:  $size-4xs;
    }
    &.no-margin-top {
        margin-top: 0;
    }
}

.table-spacer {
    margin-bottom: $size-3xs;
}
