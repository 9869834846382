
@use 'components/shared/core.scss' as *;

.main-menu-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: $size-xl;
    background-color: $menu-color;
    box-shadow: 0 5px 8px #ccc;
    z-index: 5;
}

.logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: $size-3xs;
    cursor: pointer;
    .logo-image {
        height: $size-large;
    }
}

.main-menu-toggle-container {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: $size-xl;
    width: $size-xl;
    .main-menu-toggle.button {
        &:focus {
            filter: brightness(100%);
        }
        .icon {
            width: $size-xs;
            height: $size-xs;
            svg {
                width: $size-xs;
                height: $size-xs;
            }
        }
    }
}

.main-menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width:auto;
    height: $size-xl;
    transition: all .2s;
    white-space: nowrap;
    box-sizing: border-box;
    overflow-x: scroll;
    overflow-y: hidden;
    &::-webkit-scrollbar {
        height: $size-5xs;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $tertiary-color;
        border-radius: 10px;
        cursor: pointer;
    }
}

@media (max-width: $breakpoint-phone-width) {
    .main-menu-toggle-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: $size-xl;
        .main-menu-toggle.button {
            &:focus {
                filter: brightness(100%);
            }
            .icon {
                width: $size-xs;
                height: $size-xs;
                svg {
                    width: $size-xs;
                    height: $size-xs;
                }
            }
        }
    }
    
    .main-menu {
        position: absolute;
        flex-direction: column;
        align-items: flex-start;
        top: $size-xl;
        right: 0px;
        max-height: 0px;
        width: 180px;
        transition: all .2s;
        z-index: 5;
        background-color: $secondary-color;
        overflow-y: scroll;
        &.open {
            max-height: calc(100% - $size-xl);
            height: calc(100% - $size-xl);
        }
        &::-webkit-scrollbar {
            display: none;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $tertiary-color;
            border-radius: 10px;
            cursor: pointer;
        }
    }
}