@use 'components/shared/core.scss' as *;

.location-gameroom-type-error {
    width: 100%;
    margin-top:15%;
    text-align: center;
    color: $secondary-color;
}
.location-gameroom-table-title.margin-top {
    margin-top: $size-4xs;
}

.location-gameroom-confirm-button {
    margin-top: calc($size-xs + 2px);
}
