@use 'components/shared/core.scss' as *;

.forgot-password {
    height: calc(100vh - $header-height);
}

.forgot-password-form-container {
    color: $white;
    position: absolute;
    top: 50%;
    left : 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 600px;
    background-color: $grey-darkest;
    border-radius: $radius;
    padding-bottom: $size-xxs;
    box-shadow: 0px 0px 150px #fff8;
    text-align: center;

    .input-label {
        color: $white;
        margin-left: 0;
        padding-left: 0;
    }
  .forgot-password-input {
    color: $black;
  }
}

.forgot-password-logo {
  margin-top: $size-3xs;
  margin-bottom: $size-3xs;
  text-align: center;

  .forgot-password-logo-image {
    height: 100px;
  }
}

.forgot-password-error {
  height: $size-xxs;
  min-height: $size-xxs;
  font-size: $size-font;
  line-height: $size-xxs;
  margin-bottom: $size-5xs;
  text-align: center;
  color: $red;
}

.forgot-cancel-link-container {
    margin-top: $size-3xs;
}

.forgot-form {
  height: $size-5xl !important;
}

.forgot-timer-container, .forgot-message-container, .forgot-notice-container  {
  display: flex;;
  flex-direction: row;;
  justify-content: center;
  height: calc($size-5xl / 4 * 3)!important;
}

.forgot-notice-container {
  height: calc($size-5xl / 2)!important;
}

.resend-span {
  font-size: $size-font-xl;
}
