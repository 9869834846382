@use 'components/shared/core.scss' as *;

.service-item {
    position: relative;
    border: 3px solid $secondary-color-lighter;
    border-radius: $radius;
    .checkbox-container {
        margin-top: $size-xxs;
        padding-right: $size-large;
    }
    .input-label {
        text-align: right;
        margin-right: $size-3xs;
    }
}

.service-item-contents {
    &.disabled { 
        opacity: .4;
        pointer-events: none;
    }
}

.service-item-logo-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: $size-3xs;
    height: 150px;
}

.service-item-logo {
    max-height: 150px;
    max-width: 300px;
}

.service-item-label {
    width: 100%;
    font-weight: bold;
    font-size: $size-font-xl;
    line-height: $size-xl;
    text-align: center;
    height: $size-xl;
}

.service-item-error {
    height: $size-xs;
    line-height: $size-xs;
    font-size: $size-font;
    padding-left: $size-5xs;
    text-align: center;
    color: $red;
}

.service-item-banner {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    bottom: 48%;
    width: 100%;
    background-color: $secondary-color;

}
.service-item-banner-text{
    text-align: center;
    color: $white;
    border-top: 3px solid $white;
    border-bottom: 3px solid $white;
    margin-top: $size-5xs;
    margin-bottom: $size-5xs;
    font-size: $size-xxs;
    width: 85%;
    max-width: 450px;
    font-weight: bolder
}