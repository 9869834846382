@use 'components/shared/core.scss' as *;

.space-bottom {
    margin-bottom: $size-3xs;
}

.company-details-submit-button{
    align-self: flex-end;
    margin-bottom: $size-xxs;
}

.company-details-branding-image{
    width: 185px;
    height: 42px;
    display: block;
    margin: auto;
}

.custom-file-upload {
    padding: 6px 12px;
    box-sizing: border-box;
    cursor: pointer;
    &.display {
        cursor: default;
    }
}
