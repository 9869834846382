@use 'components/shared/core.scss' as *;
.login {
    height: calc(100vh - $header-height);
}
.login-form-container {
    position: absolute;
    top: 50%;
    left : 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 600px;
    background-color: $grey-darkest;
    border-radius: $radius;
    padding-bottom: $size-xxs;
    box-shadow: 0px 0px 150px #fff8;
    .input-label {
        color: $white;
        margin-left: 0;
        padding-left: 0;
    }
}

.login-logo {
    margin-top: $size-3xs;
    margin-bottom: $size-3xs;
    text-align: center;
    .login-logo-image {
        height: 100px;
    }
}

.login-error {
    height:$size-xxs;
    min-height: $size-xxs;
    font-size: $size-font;
    line-height: $size-xxs;
    margin-bottom: $size-5xs;
    text-align: center;
    color: $red;
}

.login-forgot-link-container {
    margin-top: $size-3xs;

}

@media (max-width: $breakpoint-phone-width) {
    .login-form-container {
        width: 85%;
        padding: $size-3xs;
    }
    .login-logo .login-logo-image {
        height: 70px;
    }
}