@use 'components/shared/core.scss' as *;

.row-count-picker {
    display: flex;
    flex-direction: row;
    align-items: center;
    label {
        width: 50px;
        font-size:$size-font;
    }
    .label-first{
        text-align: end;
        margin-right: $size-5xs;
    }
    .label-last{
        text-align: start;
        margin-left: $size-5xs;
    }
}

.row-count-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: $size-xxl;
    height: $size-mid;
}

.row-count-display {
    width: 100%;
    height: $size-mid;
    line-height: $size-mid;
    background-color: white;
    border: 1px solid $grey;
    border-radius: $radius-small;
    color: $primary-color;
    text-align: center;
    font-weight: bold;
    box-sizing: border-box;
    cursor: pointer;
    transition: all .025s;
    &.open {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        transition: all .025s;
        border: 1px solid $secondary-color;
        border-top: 1px solid $white;
    }
    &.placeholder {
        color: rgba($grey-darker, 0.5);
    }
}
.row-count-menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 100%;
    width: 100%;
    max-width: 200px;
    max-height: 0px;
    transition: max-height .2s;
    box-sizing: border-box;
    overflow: hidden;
    background-color: $white;
    border-top-left-radius: $radius-small;
    border-top-right-radius: $radius-small;
    z-index: 5;
    &.open {
        max-height: 600px;
    }
    &.border{
        border: 1px solid $secondary-color;
        border-bottom: none;
    }
}

.row-count-option {
    margin-top: $size-6xs;
    margin-bottom: $size-6xs;
    line-height: $size-xxs;
    font-size:$size-font;
    text-align: center;
    cursor:pointer;
    &:hover {
        background-color: $secondary-color-lighter;
    }
    &.placeholder {
        cursor: default;
        color: rgba($primary-color, 0.5);
        &:hover {
            background-color: $white;
        }
    }
}