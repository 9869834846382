@use 'sass:color';

// Breakpoints
$breakpoint-phone-width: 500px;
$breakpoint-tablet-width: 1200px;

$size-font-xl: 20px;
$size-font-large: 16px;
$size-font: 14px;
$size-font-small: 12px;
$size-font-xs: 10px;

$size-6xl: 92px;
$size-5xl: 84px;
$size-4xl: 76px;
$size-3xl: 68px;
$size-xxl: 60px;
$size-xl: 52px;
$size-large: 44px;
$size-mid: 36px;
$size-small: 30px;
$size-xs: 24px;
$size-xxs: 18px;
$size-3xs: 14px;
$size-4xs: 10px;
$size-5xs: 6px;
$size-6xs: 3px;

$header-height: $size-3xl;
$footer-height: 40px;
$main-menu-width: 200px;
$input-height: 38px;
$page-header-height: 40px;
$search-bar-height: 75px;
$table-search-bar-height: $size-4xl;
$table-nav-bar-height: $size-4xl;
$table-row-height: $size-xl;

$letter-spacing: 0px;
$text-shadow: none;
$default-font-family: 'proxima-nova', sans-serif;

// Grey Scale
$white: white; // #FFFFFF
$display-white: rgb(248, 248, 248); // #F8F8F8
$off-white: rgb(240, 240, 240); // #F0F0F0
$grey-mask: rgb(230, 230, 230); // #E6E6E6
$grey-lightest: rgb(221, 221, 221); // #DDDDDD
$grey-lighter: rgb(192, 192, 192); // #C0C0C0
$grey-light: rgb(160, 160, 160); // #A0A0A0
$grey: rgb(128, 128, 128); // #808080
$grey-dark: rgb(102, 102, 102); // #666666
$grey-darker: rgb(51, 51, 51); // #333333
$grey-darkest: rgb(32, 32, 32); // #202020
$black: black; // #000000

$mask-wipe: rgba(255, 255, 255, 0.6); //white with 60% opacity
$table-cell-borders: rgba($grey, 0.25); //$grey with 25% opacity

// Colors
$red: rgb(255, 74, 85); // #FF4A55
$orange: rgb(252, 147, 41); // #FC9329
$warning: rgb(182, 113, 29); // #B6711D
$yellow: rgb(255, 224, 120); // #FFE078
$green: rgb(76, 175, 80); // #4CAF50
$green-light: rgb(184, 235, 199); // #B8EBC7
$blue: rgb(51, 122, 183); // #337AB7
$teal: rgb(13, 140, 178); // #0D8CB2
$purple: rgb(106, 20, 209); // #6A14D1
$menu-color: rgb(18, 122, 201); // #127AC9

$primary-color: $grey-dark; // #666666
$primary-color-darkest: color.scale($primary-color, $lightness: -75%); // #080808
$primary-color-darker: color.scale($primary-color, $lightness: -50%); // #333333
$primary-color-dark: color.scale($primary-color, $lightness: -5%);    // #5F5F5F
$primary-color-light: color.scale($primary-color, $lightness: 15%);   // #7A7A7A
$primary-color-lighter: color.scale($primary-color, $lightness: 35%); // #B3B3B3
$primary-color-lightest: color.scale($primary-color, $lightness: 55%);// #D9D9D9

$secondary-color: rgb(20, 121, 197);                                  // #1479C5
$secondary-color-darkest: color.scale($secondary-color, $lightness: -75%); // #020417
$secondary-color-darker: color.scale($secondary-color, $lightness: -50%); // #073E62
$secondary-color-dark: color.scale($secondary-color, $lightness: -25%);   // #0A577E
$secondary-color-light: color.scale($secondary-color, $lightness: 15%);   // #3593D1
$secondary-color-lighter: color.scale($secondary-color, $lightness: 45%); // #85C4E9
$secondary-color-lightest: color.scale($secondary-color, $lightness: 55%);// #A5D4EF


$tertiary-color: rgb(232, 197, 115); // #E8C573

$colors: (
  'primary': $primary-color,
  'secondary': $secondary-color,
  'red': $red,
  'orange': $orange,
  'warning': $warning,
  'yellow': $yellow,
  'green': $green,
  'green-light': $green-light,
  'blue': $blue,
  'teal': $teal,
  'purple': $purple
);


$grad-primary: linear-gradient(45deg, $primary-color 0%, $primary-color-light 100%);
$grad-secondary: linear-gradient(45deg, $secondary-color 0%, $secondary-color-light 100%);
$grad-red: linear-gradient(45deg, rgba(239, 10, 106, 1) 0%, rgba(182, 53, 156, 1) 100%);
$grad-orange: linear-gradient(135deg, rgba(252, 104, 111, 1) 1%, rgba(255, 147, 76, 1) 100%);
$grad-fire-rise: linear-gradient(45deg, #f83600 0%, #facc22 100%);
$grad-fire-fall: linear-gradient(10deg, #facc22 0%, #f83600 100%);
$grad-yellow: linear-gradient(110deg, #ffd828 0%, #ffc928 10%);
$grad-gold-rise: linear-gradient(45deg, #ff9100 10%, #ffd828 100%);
$grad-gold-fall: linear-gradient(110deg, #ffd828 0%, #ff9100 90%);
$grad-green: linear-gradient(45deg, rgba(98, 255, 227, 1) 0%, rgba(0, 190, 22, 1) 100%);
$grad-blue-right: linear-gradient(135deg, #4760ff 0%, #0dccff 100%);
$grad-blue-left: linear-gradient(45deg, #0dccff 0%, #4760ff 100%);
$grad-blue-pale: linear-gradient(45deg, #6771e6 38%, #55329c 88%);
$grad-blue-dark: linear-gradient(45deg, #061eb7 0%, #006cd0 100%);
$grad-blue-light: linear-gradient(45deg, #01e9bd 0%, #007cde 100%);
$grad-purple: linear-gradient(135deg, #bc00a4 0%, #6a14d1 100%);
$grad-grey: linear-gradient(135deg, $grey-lighter 0%, $grey-darker 100%);
$grad-grey-dark: linear-gradient(135deg, $grey-darker 0%, $grey-darkest 100%);

$gradients: (
  'secondary': $grad-secondary,
  'red': $grad-red,
  'orange': $grad-orange,
  'fire-rise': $grad-fire-rise,
  'fire-fall': $grad-fire-fall,
  'yellow': $grad-yellow,
  'gold-rise': $grad-gold-rise,
  'gold-fall': $grad-gold-fall,
  'green': $grad-green,
  'blue-right': $grad-blue-right,
  'blue-left': $grad-blue-left,
  'blue-pale': $grad-blue-pale,
  'blue-light': $grad-blue-light,
  'blue-dark': $grad-blue-dark,
  'purple': $grad-purple,
  'grey': $grad-grey,
  'grey-dark': $grad-grey-dark
);

// Radius
$radius-xxl: 25px;
$radius-xl: 20px;
$radius-large: 15px;
$radius: 10px;
$radius-mid: 8px;
$radius-small: 6px;
$radius-xs: 4px;

$cell-icon: $size-6xl;
$cell-xs: 100%;
$cell-small: 200%;
$cell-mid: 250%;
$cell-large: 300%;
$cell-xl: 400%;

$sixth: calc(100%/6 - $size-5xs*4);
$quarter: calc(25% -  $size-5xs*2);
$third: calc(33% -  $size-5xs*2);
$half: calc(50% -  $size-5xs*2);
$two-thirds: calc(66% -  $size-5xs*2);
$three-quarters: calc(75% - $size-5xs*2);
$full: calc(100%);