@use 'components/shared/core.scss' as *;

.finance-display-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    box-sizing: border-box;
    padding-left:$size-xxs;
    padding-right: $size-xxs;
    .finance-display.grid {
        width: 100%;
        grid-row-gap: $size-5xs;
        grid-column-gap: 0px;
        .bold {font-weight: bold}
        .right {text-align: right}
        .red-text {color: $red}   
        div {
            text-wrap: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }    
    .val-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
}

.finance-display-title {
    grid-column: span 2;
    text-align: center;
    font-weight: bold;
    font-size: $size-xxs;
    margin-top: $size-4xs;
}