@use 'components/shared/core.scss' as *;

.context-menu-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: $size-small;
    height: 100%;
    width: 100px;
    font-size:$size-font;
    margin-left: auto;
    &.open {
        .context-menu {
            height: 250px;
            max-height: 250px;
            border: 2px solid $secondary-color;
        }
    }
}

.context-button {
    position: relative;
    height: $size-small;
    line-height: $size-small;
    padding-left: $size-4xs;
    padding-right: $size-4xs;
    border-radius: $radius-xxl;
    margin-top: $size-xs;
    color: $white;
    background-color: $secondary-color;
    i {
        position: relative;
        left: 5px;
    }
    cursor: pointer;
    &.disabled {
        filter: brightness(80%);
        pointer-events: none;
        cursor: default;
    }
}
.context-menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 230px;
    max-height: 0px;
    background-color: $primary-color;
    border-radius: $radius;
    overflow: hidden;
    z-index: 10;
    transition: all .2s ease-in-out;
}