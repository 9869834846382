@use 'components/shared/core.scss' as *;

.date-time-picker {
  .react-datepicker-wrapper {
    width: 100%;
    max-width: 400px; // Much more, and the arrow top of the calendar dropdown breaks

  }
  .react-datepicker-popper {
    z-index: 3;
  }
  .date-time-label{
    height: $size-xxs;
    margin-left: $size-4xs;
    margin-bottom: $size-5xs;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    font-size: $size-font;
    font-weight: bold;
    &.hide-label {
        height: 0px;
        min-height: 0px;
    }
  }
  input {
    width: 100%;
    height: $size-mid;
    line-height: $size-mid;
    padding-left: $size-3xs;
    padding-right: $size-3xs;
    border: none;
    border-radius: $radius-small;
    color: $black;
    background-color: $off-white;
    box-sizing: border-box;
    outline: none;
    font-size: $size-font;
    cursor: pointer;
  }
}