@use 'components/shared/core.scss' as *;
.device-action-modal {
  &.modal-backdrop {
    .modal-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 300px;
    }
    .modal-title {
      font-size: $size-mid;
      color: $primary-color;
      height: $size-xl;
    }

    .action-message{
      font-size: $size-xxs;
      color: $primary-color;
      height: 100%;
      text-align: justify;
    }
    .action-error-message{
      font-size: $size-xxs;
      color: $red;
      height: 100%;
      text-align: justify;
    }
    .modal-content {
      height: 100%;
      justify-content: space-between;
    }
  }
}

.device-action-modal-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 80%;
}