@use 'components/shared/core.scss' as *;

.table-nav-bar-container {
    position: sticky;
    bottom: 0px;
    left: -1px;
    z-index: 3;
    height: $table-row-height;
    min-height: $table-row-height;
    border-top: 1px solid $grey;
    background-color: $off-white;
    border-bottom-left-radius: $radius;
    border-bottom-right-radius: $radius;
    &.hide {
        display: none;
    }
    &.static {
        position: relative;
    }
    &.side-scrollable {
        bottom: 0px;
    }
}

.table-nav-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: $table-row-height;
    background-color: $white;
    border-bottom-left-radius: $radius-mid;
    border-bottom-right-radius: $radius-mid;
}