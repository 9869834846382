@use 'components/shared/core.scss' as *;

.show-record-card {
    #gameroomPerformanceReports.table {
        .table-section, .search-bar-right-container {
            display: none;
        }
    }
}

.card {
    .card-content.grid {
        .financials-summary-label {
            font-size: $size-3xs;
            font-weight: bold;
            margin-bottom: $size-5xs;
            .left {
                padding-left: 20%;
                width: 80%;
            }
        }
        
        .financials-summary-value {
            margin-bottom: $size-5xs;
            text-align: right;
            width: 80%;
            .percentage {
                text-align: right;
                width: 80%;
                max-width: 150px; //Same as accounting cell
            }
        }
    }
}