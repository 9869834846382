@use 'components/shared/core.scss' as *;

.device-transactions-type-error {
    width: 100%;
    margin-top:15%;
    text-align: center;
    color: $secondary-color;
}

.device-controls-table-label {
    font-size: $size-xs;
    color: $primary-color-darker;;
    height: $size-xs;
    margin-bottom: $size-3xs;
    margin-top: $size-3xs;
    margin-left: $size-3xs;
}
