@use 'components/shared/core.scss' as *;

.table-settings-container.grid {
    position: absolute;
    top: $table-search-bar-height;
    right: 0px;
    height: 0px;
    z-index: 10;
    width: 250px; // arbitrary value
    border-bottom-left-radius: $radius-small;
    border-bottom-right-radius: $radius-small;
    overflow: hidden;
    transition: all .1s ease-in-out;
    background-color: $primary-color-lighter;
    box-sizing: border-box;
    padding-left: $size-3xs;
    padding-right: $size-3xs;
    &.open {
        height: 250px; // arbitrary value
    }
}

.column-picking-section {
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
}

.column-picking-title {
    height: $size-small;
    line-height: $size-small;
    font-weight: bold;
    padding-left: $size-3xs;
    color: $white;
}

.column-picking-card {
    overflow-y: scroll;
    height: calc(250px - $size-small - $size-5xs);
    background-color: $white;
    padding-left: $size-3xs;
    box-sizing: border-box;
    margin-bottom: $size-5xs;
    border-radius: $radius-small;
}

.table-settings-container .checkbox-container {
    height: $size-small;
    margin-top: $size-6xs;
}