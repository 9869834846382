@use 'components/shared/core.scss' as *;

.table-header-cell {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: $primary-color-darker;
    font-weight: 700;
    font-size: $size-font;
    overflow: hidden;
    background-color: $white;
    height: 100%;
    box-sizing: border-box;
    &.icon {
        width: $cell-icon;
        min-width: $cell-icon;
    }
    &.xs {
        width: $cell-xs;
    }
    &.small {
        width: $cell-small;
    }
    &.mid {
        width: $cell-mid;
    }
    &.large {
        width: $cell-large;
    }
    &.xl {
        width: $cell-xl;
    }
    &.hidden {
        display:none;
    }
}


.table-header-cell-contents {
    position: relative;
    max-width: 75%;
}

.table-header-cell-text {
    text-align: left;
    line-height: $size-font;
    max-height: calc($size-font * 2);
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    &.no-click {
        cursor: default;
    }
}

.table-header-sort-arrow {
    position: absolute;
    top: 0px;
    right: -$size-small;
    height: $size-3xs;
    cursor: pointer;
    &.hide {
        display: none;
    }
    svg {
        border: 1px solid $secondary-color;
        color: $secondary-color;
        border-radius: 50%;
        height: $size-3xs;
        width: $size-3xs;
    }
}

.table-header-cell.text-left {
    text-align: left;
    justify-content: flex-start;
    border-left: none;
    .table-header-cell-text {
        padding-left: $size-4xs;
    }
    .header-cell-contents {
        width: calc(100% - $size-4xs);
        padding-right: $size-4xs;
        .cell-left-padding {
            flex: 0;
        }
    }
}

.table-header-cell.text-right {
    justify-content: flex-end;
    .table-header-cell-contents {
        padding-right: $size-small;
        .table-header-cell-text {
            text-align: right;
        }
        .table-header-sort-arrow {
            right: 0;
        }
    }
    &.right-pad {
        border-left: none;
        .header-cell-contents {
            margin-right: $size-xxs;
            width: calc(100% - $size-xxs - $size-3xs);

        }
    }
}

