@use 'components/shared/core.scss' as *;

.table-header {
    position: sticky;
    top: 0px;
    z-index: 1;
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    font-weight: bold;
    height: $table-row-height;
    min-height: $table-row-height;
    box-sizing: border-box;
    border-bottom: 1px solid;
    border-color: $table-cell-borders;
    background-color: white;
    color: $primary-color-darker;
}

.table-header-loading-mask {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    transition: all .2s;
    z-index: 2;
    &.hide {
        opacity: 0;
        z-index: -1;
    }
    .spinner {
        width: $size-xxs;
        height: $size-xxs;
    }
}

.table-header-cell {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    color: $grey-dark;
    font-weight: 700;
    &.text-left {
        text-align: left;
        justify-content: flex-start;
        border-left: none;
        .header-cell-contents {
            margin-left: $size-4xs;
            width: calc(100% - $size-4xs - $size-3xs);
            .cell-left-padding {
                flex: 0;
            }
        }
    }

    &.text-right {
        justify-content: flex-end;
        .header-cell-contents {
            align-items: flex-end;
            width: calc(100% - $size-xs);
            padding-left: $size-xs;
            .table-header-cell-text {
                text-align: right;
            }
            .cell-filter-container {
                flex: 0;
                display: block;
                margin-right: $size-3xs;
            }
        }
        &.right-pad {
            border-left: none;
            .header-cell-contents {
                margin-right: $size-xxs;
                width: calc(100% - $size-xxs - $size-3xs);

            }
        }
    }
    &.icon {
        width: $cell-icon;
        min-width: $cell-icon;
    }
    &.xs {
        width: $cell-xs;
    }
    &.small {
        width: $cell-small;
    }
    &.mid {
        width: $cell-mid;
    }
    &.large {
        width: $cell-large;
    }
    &.xl {
        width: $cell-xl;
    }
    &.sticky-column {
        position: sticky;
        left: 0px;
        background-color: $white;
    }
    &.hidden {
        display: none;
    }
}

.header-cell-contents {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.cell-text {
    position: relative;
    text-wrap: nowrap;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    cursor: pointer;
    &.no-sorting {
        cursor: default;
    }
}

.table-header-sort-container {
    position: absolute;
    top: 0px;
    right: 0px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: red;
    &.hide {
        display: none;
    }
}