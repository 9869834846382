@use 'components/shared/core.scss' as *;

.location-gameroom-type-error {
    width: 100%;
    margin-top:15%;
    text-align: center;
    color: $secondary-color;
}


.location-gameroom-table-title {
    font-size: $size-xs;
    color: $grey-darker;
    height: $size-xs;
    margin-bottom: $size-3xs;
    margin-left: $size-3xs;
}

.location-gameroom-confirm-button {
    margin-top: calc($size-xs + 2px);
}
