@use 'components/shared/core.scss' as *;
.delete-accounts-modal {
   .modal-title {
      color: $orange;
   }
}

.delete-accounts-modal-warning { 
   font-size: $size-xxs;
   margin-bottom: $size-3xs;
}

.delete-accounts-modal-container {
   background-color: $red;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: flex-start;
}

.delete-accounts-modal-header {
   font-weight: bold;
   text-align: center;
}

.delete-accounts-modal-label, .delete-accounts-modal-result {
   display: flex;
   flex-direction: column;
   justify-content: center;
   height: $size-large;
}

.delete-accounts-modal-label {
   padding-left: $size-3xs;
}

.delete-accounts-modal-result {
   text-align: center;
   &.delete-successful {
      font-weight: bold;
      color: $green;
   }
   &.delete-failed {
      font-weight: bold;
      color: $red;
   }
}

 .delete-accounts-modal-error {
   min-height: $size-xxs;
   line-height: $size-xxs;
   font-size: $size-3xs;
   color: $red;
   margin-top: $size-3xs;
   margin-bottom: $size-3xs;
 }

.delete-accounts-modal-button-row {
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
}

.delete-accounts-modal-success-card {
   height: 300px;
   width: 100%;
}
