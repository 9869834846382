@use 'components/shared/core.scss' as *;
@use 'sass:color';

.table-cell {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    &.icon {
        width: $cell-icon;
        min-width: $cell-icon;
    }
    &.xs {
        width: $cell-xs;
    }
    &.small {
        width: $cell-small;
    }
    &.mid {
        width: $cell-mid;
    }
    &.large {
        width: $cell-large;
    }
    &.xl {
        width: $cell-xl;
    }
    &.placeholder {
        color: $grey-lighter;
    }
    &.text-left {
        text-align: left;
        border-left: none;
        .table-cell-content {
            align-items: flex-start;
            padding-left: $size-4xs;
            width: calc(100% - $size-4xs - 1px);
        }
    }
    &.spacer {
        border-right: none;
    }
    &.text-right {
        text-align: right;
        .table-cell-content {
            align-items: flex-end;
            margin-right: calc($size-xs + (2 * $size-6xs));
            width: calc(100% - $size-xs - (2 * $size-6xs));
        }
    }
    &.spacer {
        border-right: none;
    }
    &.hidden {
        display: none;
    }
}

.table-cell-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-height: $table-row-height;
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
}

.table-cell-image {
    max-height: $size-xxl;
    &.placeholder  {
        max-width: 50px;
        opacity: .5; 
    }
}

.cell-negative {
    color: $red;
}


.table-cell-link-icon {
    background-color: $primary-color-light;
    border-radius: 50%;
    width: $size-mid;
    height: $size-xs;
    line-height: $size-xs;
    i {
        color: $white;
    }
}

.table-checkbox {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    width: $size-xxs;
    height: $size-xxs;
    
    input[type='checkbox'] {
        display: none;
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        width: $size-3xs;
        height: $size-3xs;
        background-color: $primary-color-lightest;
        border: 1px solid $primary-color-lighter;
        border-radius: 4px;
        &::before {
            content: '';
            position: absolute;
            display: none;
            left: 5px;
            top: 2px;
            width: 3px;
            height: 6px;
            border: solid $white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }
    }

    input[type='checkbox']:checked + .checkmark::before {
        display: block;
    }

    input[type='checkbox']:checked + .checkmark {
        background-color: $secondary-color;
        border-color: $secondary-color;
    }
}
.accounting-cell-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    max-width: 150px;
}

.table-indicator {
    background: radial-gradient(
        circle at center,
        color.scale($red, $lightness: 10%) 0%,   
        $red 70%,                                
        color.scale($red, $lightness: -10%) 100% 
    );
    border-radius: 50%;
    width: $size-xxs;
    height: $size-xxs;
    position: relative;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);

    /* Adding a pseudo-element for the highlight */
    &:after {
        content: '';
        position: absolute;
        top: 10%;    /* Adjust these values to move the highlight */
        left: 60%;   /* Adjust these values to move the highlight */
        width: 30%;
        height: 30%;
        background: radial-gradient(circle, rgba(255, 255, 255, 0.8), transparent);
        border-radius: 50%;
        transform: translate(-50%, -50%);
    }
    &.on {
        background: radial-gradient(
            circle at center,
            color.scale($green, $lightness: 10%) 0%,   
            $green 70%,                                
            color.scale($green, $lightness: -10%) 100% 
        );
    }
}