@use 'components/shared/core.scss' as *;

.date-range-picker {
  margin-right: $size-3xs;
  width: 100%;
  .react-datepicker-wrapper {
    width: 100%;
  }
  .date-range-label {
    height: $size-xxs;
    min-height: $size-xxs;
    padding-left: $size-5xs;
    margin-left: $size-4xs;
    margin-bottom: $size-6xs;
    font-size: $size-3xs;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
    color: $primary-color-darker;
    cursor: default;
    &.hide-label {
        height: 0px;
        min-height: 0px;
    }
  }
  input {
    width: 100%;
    margin: 0px;
    padding-left: $size-3xs;
    padding-right: $size-3xs;
    border-radius: $radius-small;
    border: none;
    color: $black;
    background-color: $off-white;
    box-sizing: border-box;
    outline: none;
    font-size: $size-font;
    overflow:hidden;
    text-overflow: ellipsis;
    height: $input-height;

    cursor: pointer;
    box-sizing: border-box;
    &:focus {
      border: 1px solid $secondary-color;
    }
  }
  .react-datepicker-popper {
    z-index: 5;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: $white !important;
  }
  .react-datepicker__day--in-range {
      background-color: $secondary-color;
      color: $white;
      &:hover {
        background-color: $secondary-color-light;
      }
      &.react-datepicker__day--keyboard-selected {
        background-color: $secondary-color !important;
      }
  }

  .react-datepicker__day--in-selecting-range {
    background-color: $secondary-color-light !important;
    color: white;
  }

  $picker-width: 350px;

  .react-datepicker {
    width: $picker-width;
  }

  .react-datepicker__triangle {
    left: -50px !important;
    &::after {
      border-bottom-color: $white !important;
    }
  }
  .react-datepicker__navigation--previous {
    left: calc($picker-width - 240px + 2px) //New width + calendar width + original position
}
  .react-datepicker__month-container {
    float: right;
  }
  .react-datepicker__children-container {
    width: calc($picker-width - 240px);
    margin: 0px;
    height: 235px;
    padding: $size-5xs;
    border-right: 1px solid $grey-light;
    box-sizing: border-box;
  } 
}
.date-range-picker-options-list {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  height: 100%;
}

.date-range-option {
  color: $secondary-color;
  font-size: $size-font-small;
  font-weight: bold;
  margin-bottom: 8px;
  cursor: pointer;
  &:hover {
    color: $secondary-color-light;
  }
  &:active {
    transform: scale(0.97);
  }
}
