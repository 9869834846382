@use 'components/shared/core.scss' as *;

.change-pin-modal.modal-backdrop {
   .modal-container {
       display: flex;
       flex-direction: column;
       align-items: center;
       margin-left: 110px; // to center on component;
   }
   .modal-title {
       font-size: $size-xs;
       color: $orange;
   }
}

.change-pin-modal-warning-container {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: flex-start;
}

.change-pin-modal-warning { 
   font-size: $size-xxs;
}

.change-pin-modal-error {
   min-height: $size-xxs;
   line-height: $size-xxs;
   font-size: $size-3xs;
   color: $red;
   margin-top: $size-3xs;
   margin-bottom: $size-3xs;
}

.change-pin-modal-button-row {
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
}

.change-pin-modal-success-card {
   height: 300px;
   width: 100%;
}

 .change-pin-modal-warning-image {
   height: 200px;
   margin-bottom: $size-xs;
 }

.change-pin-modal-image {
   height: 100%;
   width: 100%;
}

.change-pin-modal-conclude-container {
   width: 100%;
   height: 100px;
   display: flex;
   flex-direction: column;
   justify-content: space-around;
   align-items: center;
}
