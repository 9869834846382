@use 'components/shared/core.scss' as *;

.menu-item-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: $size-4xs;
    height: 100%;
    box-sizing: border-box;
    margin-right: 1px;
    &:hover {
        background-color: $secondary-color-light;
    }
    &.profile .menu-item .menu-item-icon {  
        margin-right: $size-xxs;
    }
}

.menu-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: auto;
    height: $size-xl;
    color: $white;
    text-transform: uppercase;
    font-size: $size-font-small;
    cursor: pointer;
    &:visited {
        color: $white;
    }
}

.menu-item-icon {
    font-size: calc($size-xs - 2px);
    margin-left: $size-3xs;
    margin-right: $size-5xs;
}

.menu-item-label {
    margin-right: $size-3xs;
}

.menu-item-children-menu {
    position: absolute;
    top: $size-xl;
    width: 200px;
    display: flex;
    flex-direction: column;
    border-bottom-left-radius: $radius-small;
    border-bottom-right-radius:$radius-small;
    background-color: $menu-color;
    overflow: hidden;
    transition: max-height .3s;
    max-height: 0px;
    z-index: 10;
    .menu-item {
        margin-left: $size-3xs;
    }
} 

@media (max-width: $breakpoint-phone-width) {
    $item-height: 48px;
    .menu-item-container {
        justify-content: flex-start;
        width: 200px;
        height: $item-height;
        max-height: $item-height;
        margin-top: 0px;
        &:hover {
            background-color: $menu-color;
        }
        &.profile  {
            align-items: center;
            >.menu-item .menu-item-icon {
                margin: 0px; // target the profile icon
            }
            .menu-item .menu-item-icon {
                margin-right: $size-4xs; // icons in the profile menu
            }
        }
    }
    .menu-item {
        height: $item-height;
        margin-top: 0px;
    }
    .menu-item-children-menu {
        position: relative;
        top: 0px;
        justify-content: flex-start;
        height: 0px;
        max-height: 0px;
    } 

    .menu-item-container.open {
        height: auto;
        max-height: 1000px;
        .menu-item {
            margin-top: 0px;
            height: $item-height;
        }
        .menu-item-children-menu {
            height: auto;
            max-height: 1000px;
        }
    }
} 