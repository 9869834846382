@use 'components/shared/core.scss' as *;

.header {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: $header-height;
    box-sizing: border-box;
}

.header-label-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height :100%;
    box-sizing: border-box;

}
.header-label-container:has(.header-links-container) {
    justify-content: flex-start;
    padding-top: $size-4xs;
}

.header-label {
    font-size: $size-xs;
    color: $primary-color-darker;
    line-height: $size-xs;
    font-weight: 500;
    margin-left: $size-xs;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-word;
    box-sizing: border-box;
}

.header-links-container {
    position: absolute;
    bottom: $size-4xs;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-left: calc($size-xs + 2px);
    align-items: flex-end;
}

.header-links-divider {
    color: $primary-color;
    font-size: $size-xxs;
    line-height: $size-3xs;
    height: $size-3xs;
    margin-right: $size-5xs;
}

.header-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: $size-3xs;
    color: $secondary-color;
    margin-right: $size-5xs;
    font-size: $size-3xs;
    cursor: pointer;
}

.header-tabs-container {
    overflow: hidden;
}

.header-nav-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: $size-3xs;
}

.header-add-button.button {
    background-color: $secondary-color;
    &.spaced {
        margin-right: $size-xs;
    }
}


@media(max-width: $breakpoint-phone-width) {
    .header {
        position: fixed;
        z-index: 2;
        height: $size-xl;
        width: calc(100% - 10px - 14px); // left padding + scrollbar gutter 
        background-color: $off-white;
        box-sizing: border-box;
        .header-add-button.button, .page-menu-button.button {
            height: $size-small;
        }
    }
    .header-label-container:has(.header-links-container) {
        justify-content: flex-start;   
        padding: 0px;
        padding-top: $size-5xs;
    }
    .header-label {
        height: $size-xs;
        line-height: $size-xs;
        font-size: $size-font-xl;
        margin-left: $size-6xs;
    }
    .header-links-container {
        width: 100%;
        bottom: $size-5xs;
        margin-left: $size-6xs;
    }
    .header-add-button.button.page-tabs {
        margin-right: $size-mid;
    }
}