@use 'components/shared/core.scss' as *;

.dropdown-option {
    width: 100%;
    height: 100%;
    padding: $size-6xs $size-5xs $size-6xs $size-5xs;
    line-height: $size-xxs;
    font-size: $size-font;
    text-align: left;
    word-wrap: break-word;
    box-sizing: border-box;
    cursor:pointer;
    &:hover {
        background-color: $secondary-color-lighter;
    }
    &:focus {
        background-color: $secondary-color-lighter;
        border: none;
        outline: none;
        &:hover {
            background-color: $secondary-color-light;
        }
    }
    &.placeholder {
        cursor: default;
        &:hover {
            background-color: $grey-lightest;
        }
    }
}