@use 'components/shared/core.scss' as *;

.permissions-row.row {
    width: 60%;
}

.permissions-card-label {
    height: 18px;
    width: 100%;
    padding-left: 6px;
    min-height: 18px;
    margin-left: 10px;
    font-size: 14px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    color: $primary-color-darker;;
}

.permissions-no-results {
    color: $grey;
    font-size: 14px;
    margin-bottom: $size-3xs;
    font-style: italic;
    width: 100%;
    text-align: center;
}

.permissions-no-user {
    color: $grey;
    font-size: 14px;
    font-style: italic;
    width: 100%;
    height: 100%;
    line-height: 100%;
    text-align: center;
}

.permissions-user-line-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: $size-3xs;
    height: 100%;
    max-height: 600px;
    overflow-y: scroll;
    overflow-x: hidden;
    :nth-child(1).permissions-user-line {
        border-top-right-radius: $radius;
        border-top-left-radius: $radius;
    }
    :last-child.permissions-user-line {
        border-bottom-right-radius: $radius;
        border-bottom-left-radius: $radius;
        border-bottom: 1px solid $grey;
    }
}

.permissions-user-line {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: $size-mid;
    border: 1px solid $grey;
    border-bottom: none;
    transition: background-color .1s ease;
    box-sizing: border-box;
    &.permissions-header {
        border: none;
    }
}

.permissions-user-cell {
    height: $size-mid;
    line-height: $size-mid;
    color: $primary-color-darker;;
    text-align: center;
    font-size: $size-3xs;
    padding-left: $size-3xs;
    margin-right: $size-3xs;
    text-align: left;
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    &.permissions-header {
        font-weight: bold;
    }
    &.name {
        width: 35%;
    }
    &.email {
        width: 65%;
    }
}

.permissions-user-button-cell {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 25%;
    height: $size-mid;
    line-height: $size-mid;
    color: $primary-color-darker;;
    text-align: left;
    font-size: $size-3xs;
}

.permissions-user-remove {
    height: calc($size-mid - $size-4xs);
    max-width: 100px;
    width: 80%;
    color: $white;
}


.permissions-button-row.row {
    margin-top: $size-3xs;
    margin-bottom: $size-3xs;
}