@use 'components/shared/core.scss' as *;

.table-page-selector-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: width 0.1s ease;
    padding-right: $size-xs;
    width: 400px;
}

.no-table-page-selector-buttons {
    width: 100%;
    height: $size-mid;
    border: 1px solid $primary-color; 
    line-height: $size-mid;
    text-align: center;
    background-color: $grey-lighter;
}

.table-page-selector-numbers {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100%;
    width: 100%;
    .table-page-selector-number, .table-page-selector-concat {
        font-size: $size-font;
        background-color: transparent;
    }
}

.table-page-selector-button, .table-page-selector-concat {
    text-align: center;
    height: $size-mid;
    line-height: $size-mid;
    font-size: $size-font;
    border: 1px solid  $table-cell-borders;
    box-shadow: none;
    cursor: pointer;
    transition: all .3s;
    background-color: $white;
    color: $grey-dark;
    width: 100%;
    &.no-vals{
        background-color: $grey-lighter;
        cursor: default
    }
    &.first{
        border-right: 1px solid $table-cell-borders;
        border-top-left-radius: $radius-xs;
        border-bottom-left-radius: $radius-xs;
        &.disabled {
            border-right: 1px solid $grey-lighter;
        }
    }
    &.end {
        width: 100px;
        color: $grey;
    }
    &.direction {
        width: $size-mid;
        min-width:  $size-mid;
        &.prev {
            width: 150px;
        }
        &.next {
            width: $size-6xl;
        }
    }
    &.last{
        border-left: 1px solid $table-cell-borders;
        border-top-right-radius: $radius-xs;
        border-bottom-right-radius: $radius-xs;
        &.disabled {
            border-left: 1px solid $grey-lighter;
        }
    }
    &.current {
        background-color: $secondary-color;
        color: $white;
    }
    &.disabled {
        color: $grey-lighter;
        background-color: $grey;
        cursor: default;
    }
}