@use 'components/shared/core.scss' as *;

.table-search-bar {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height:$table-search-bar-height;
    min-height: $table-search-bar-height;
    box-sizing: border-box;
    border-top-right-radius: $radius-mid;
    border-top-left-radius: $radius-mid;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    font-size: $size-font-large;
    color: $primary-color-darker;;
    background-color: $primary-color-lighter;
    grid-auto-rows: $table-search-bar-height;
    padding-top: $size-5xs;
    &.hide {
        display: none;
    }
    .input-container.disabled .input-display {
        background-color: $grey-lightest;
    }
    .dropdown-container.disabled {
        .input-display::placeholder{
            color: $grey-light;
        }
        .dropdown-arrow {
            background-color: $grey-light;
        }
    }
    .input-mask {
        background-color: $white;
        &::before {
            background-color: $off-white;
        }
    }
}

.table-search-bar-content.grid {
    height: 100%;
    width: calc(100% - 300px);
    padding-left: $size-3xs;
    > * {
        margin-right: $size-3xs;
    }
    .input-container .input-label, .date-range-label {
        color: $primary-color-darker;
    }
}

.table-search-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    max-width: 300px;
    overflow: hidden;
    > * {
        margin-right: $size-3xs;
    }
    .input-container {
        width: 200px;
    }
    .input-display {
        background-color: $white;
        opacity: 1;
    }
}

.table-search-right > .table-search-settings-button.button {
    margin-top: calc($size-xxs + $size-6xs);
    height: $input-height;
    width: $size-xl;
    margin-right: $size-4xs;
    .button-text{
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        i {
            font-size: $size-xs;
            line-height: $size-xs;
            height: $size-xs;
            box-sizing: border-box;
        }
    }
}