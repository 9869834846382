@use 'components/shared/core.scss' as *;

.page {
    width: 100%;
    min-height: $size-xxl;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border-bottom-left-radius: $radius;
    border-bottom-right-radius: $radius;
    margin-bottom: $size-3xs;
}

.page.has-tabs {
    .page-content {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }
    .tab-bar {
        scrollbar-gutter: stable;
        &::-webkit-scrollbar {
            height: 5px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $tertiary-color;
            border-radius: $radius-small;
            cursor: pointer;
        }
        &::-webkit-scrollbar-track {
            background-color: $secondary-color ;
        }
    }
}

.page-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: $page-header-height;
    background-color: $off-white;
    .page-label {
        font-size: $size-mid;
        line-height: $page-header-height;
    }
    .tab-bar {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        white-space: nowrap;
        box-sizing: border-box;
        overflow-x: scroll;
        overflow-y: hidden;
    }
}

.page-content {
    height: 100%;
    width :100%; 
    padding: $size-4xs;
    border-radius: $radius;
    background-color: $white;
    box-sizing: border-box;
    &.grid {
        grid-row-gap: 0px;
    }
}

.page-label {
    margin-left: $size-3xs;
    height: $size-mid;
    font-size: $size-xs;
    color: $primary-color-darker;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    box-sizing: border-box;
}

.page-missing-data {
    width: 100%;
    margin-top: $size-xxl;
    text-align: center;
    line-height: $size-mid;
    font-size: $size-mid;
    color: $warning;
}

.no-page-content {
    text-align: center;
}

.page-spinner-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: $size-large;
    .page-spinner {
        height: $size-mid;
        width: $size-mid;
    }
}

@media (max-width: $breakpoint-phone-width) {
    .page {
        margin-top: $size-xl;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
          }
    }
    .page-content {
        border-top-left-radius: $radius;
        border-top-right-radius: $radius;
    }
    .page-menu-container {
        position: absolute;
        right: 0px;
        top: 11px;
        display:flex;
        flex-direction: row;
        justify-content: flex-end;
        width: $size-large;
        text-align: right;
        box-sizing: border-box;
        color: $white;
        font-size: $size-font;
        z-index: 3;
    }
    .page-menu-button {
        height: $size-small;
        padding: 0px;
    }
    .page-menu {
        position: absolute;
        flex-direction: column;
        align-items: flex-start;
        top: 40px; // height of header button + space below to align to page content;
        right: 0px;
        max-height: 0px;
        width: 120px;
        transition: all .2s;
        background-color: $secondary-color;
        overflow-y: scroll;
        border-bottom-left-radius: $radius-small;
        border-bottom-right-radius: $radius-small;
        z-index: 4;
        &.open {
            max-height: 600px;
        }
        &::-webkit-scrollbar {
            display: none;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $tertiary-color;
            border-radius: 10px;
            cursor: pointer;
        }
    }

    .page-menu-item {
        height: $size-small;
        line-height: $size-small;
        padding-right: $size-4xs;
        box-sizing: border-box;
        &.selected {
            background-color: $secondary-color-light;
        }
    }
}