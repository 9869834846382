@use 'components/shared/core.scss' as *;

.csv-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.pull-tabs-label {
    height: $size-xxs;
    margin-bottom: $size-5xs;
    font-size: $size-3xs;
    text-align: left;
    color: black;
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-word;
    box-sizing: border-box;
}

#csv-input {
    display: none;
}

.csv-file-display {
    height: $size-mid;
    line-height: $size-mid;
    font-size: $size-font;
    color: $grey-darkest;
    margin-left: $size-3xs;
    &.placeholder {
        font-style: italic;
        color: $grey-darker;
    }
    &.csv {
        color: $secondary-color;
    }
    &.error {
        color: $red;
    }
}

.features-placeholder {
    height: $size-mid;
    line-height: $size-mid;
    font-size: $size-font;
    margin-left: $size-3xs;
    font-style: italic;
    color: $grey-darker;
    margin-top: $size-xxs;
}

.pull-tabs-submit {
    margin-top: calc($size-xxs + $size-5xs);
}