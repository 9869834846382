@use 'components/shared/core.scss' as *;
@use 'sass:color';

.table-subtotal-container {
    position: relative;
    min-height: $table-row-height;
    border: 2px solid $secondary-color;
    & + .table-subtotal-container {
        border-top: none;
    }
    .table-row {
        min-height: $table-row-height;
    }
}

.table-row.subtitle {
    position: absolute;
    justify-content: flex-start;
    top: 0px;
    width: 100%;
    min-height: $table-row-height;
    color: $primary-color-darker;
    box-sizing: border-box;
    cursor: pointer;
    .subtitle-cell {
        z-index: 0;
        padding-left: $size-xxs;
        text-align: left;
        background-color: $off-white;
    }
    .subtitle-label {
        z-index: 2;
        font-weight: bold
    }
}

.table-subtotal-collapser {
    display: flex;
    flex-direction: column;
    max-height: $table-row-height;
    overflow-y: hidden;
}

.table-subtotal-collapser-spacer {
    width: 100%;
    min-height: $table-row-height;
}

.table-row.subtotal {
    position: absolute;
    bottom: 0px;
    width: 100%;
    min-height: $table-row-height;
    background-color: $off-white;
    color: $primary-color-darker;
    box-sizing: border-box;
    cursor: pointer;
    .cell-negative {
        color: $red;
    }
    .subtotal-label {
        z-index: 0;
    }
}

.open .table-row.subtotal {
    border-top: 1px solid $secondary-color;
}

.table-row.subtitle.open {
    border-bottom: 1px solid $secondary-color;
}

.total-container {
    .cell-negative .total {
        color: color.scale($red, $lightness: 4%);
    }
}

