@use 'components/shared/core.scss' as *;

.button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: $size-mid;
    line-height: $size-mid;
    background: $secondary-color;
    border-radius: $radius-small;
    color: $white;
    border: none;
    font-size: $size-font;
    text-shadow: $text-shadow;
    transition: all .3s;
    transition: background-color .1s ease;
    cursor: pointer;
    box-sizing: border-box;
    .button-text{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &:hover {
        filter: brightness(120%);
    }
    &:focus {
        filter: brightness(120%);
        outline: none;
    }
    &:enabled {
        &:active{
            animation: click .1s;
            box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.2);
        }
    }
    @keyframes click {
        0%, 100% {
            transform: scale(1);
        }
        50% {
            transform: scale(.98); // Adjust scaling factor as needed
        }
    }
    &.disabled, &:disabled {
        filter: brightness(80%);
        cursor: default;
        &:hover {
            filter: brightness(80%);
        }
    } 
}

.button-icon-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: $size-small;
    .icon {
        width: $size-xxs;
        height: $size-xxs;
        svg {
            height: $size-xxs;
            width: $size-xxs;
        }
    }
}

.match-labeled-input {
    margin-top:  21px; // just as it works out
}