@use 'components/shared/core.scss' as *;

.table-header-row {
    position: sticky;
    top: 0px;
    z-index: 2;
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    font-weight: bold;
    height: $table-row-height;
    min-height: $table-row-height;
    box-sizing: border-box;
    border-bottom: 1px solid $grey;
    border-color: $table-cell-borders;
    background-color: $off-white;
    color: $primary-color-darker;
    &.top {
        .table-header-cell:first-child {
            border-top-left-radius: $radius;
        }
        .table-header-cell:last-child {
            border-top-right-radius: $radius;
        }
    }
}

