@use 'components/shared/core.scss' as *;

.confirm-modal.modal-backdrop {
   .modal-title {
      color: $secondary-color
   }
}

.confirm-modal-message {
   font-size: $size-font-large;
   margin-bottom: $size-xxs;
   text-align: center;
}

@media (max-width: $breakpoint-phone-width) {
   .confirm-modal.modal-backdrop {
      align-items: center;
      .modal-container {
         margin-bottom: $size-xl; // adjust upwards a bit
      }
   }
}