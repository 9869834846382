@use 'components/shared/core.scss' as *;

.new-stage-marker-container {
    width: 100%;
    padding-left: $size-xxs;
    padding-right: $size-xxs;
    box-sizing: border-box;
}

.new-stage-marker {
    margin-bottom: $size-3xs;
    font-size: $size-xxs;
    height: $size-small;
    line-height: $size-small;
    text-align: center;
    color: $white;
    border-radius: $radius-large;
    &.blue {
        background-color: $blue;
    }
    &.grey {
        background-color: $grey;
    }
    &.green {
        background-color: $green;
    }
}

.add-user-permissions { 
    width: 50%;
}

@media (max-width: $breakpoint-phone-width) {
    .new-stage-marker-container {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        height: $size-small;
        margin-top: $size-4xs;
    }
    .new-stage-marker {
        height: $size-small;
        font-size: calc($size-font - 1px); //a specific tweak
        line-height: $size-small;
        margin-bottom: 0px;
    }
    .add-user-permissions { 
        width: 100%;
        height: 100%;
        padding-top: $size-3xs;
        .button.half {
            grid-column: span 6; //special case since this section doesn't take up the full modal width;
        }
    }    
}