@use 'components/shared/core.scss' as *;

.context-menu .context-menu-button.button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: calc(100% - ($size-4xs * 2));
    height: $size-small;
    margin: 0px;
    margin-bottom: $size-5xs;
    border-radius: $radius-small;
    font-size: $size-font;
    line-height: $size-xs;
    color: $white;
    background-image: $grad-secondary;
    text-align: center;
    cursor: pointer;
    height: $size-xs;
    &:first-child {
        margin-top: $size-5xs;
    }
}
